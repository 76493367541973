'use client';

import Script from 'next/script';

const ScriptsLoader = () => {
  return (
    <>
      <Script
        id="fb-pixel"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '554912637409250');
            fbq('track', 'PageView');
          `,
        }}
      />
      <Script
        id="gtm-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-56JL9HGJ');
            `,
        }}
      />
      <Script
        id="sportradar"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            (function (a, b, c, d, e, f) { a[e]=a[e]||[];
              a.x=b.createElement(c); a.y=b.getElementsByTagName(c)[0]; 
              x.src=d+"?id="+f;x.setAttribute("data-srtmn",e); y.parentNode.insertBefore(x,y);} 
              (window, document, "script", "https://tm.ads.sportradar.com/dist/tag-manager.js", "srtmCommands", "STM-AAAAVD")); 
              `,
        }}
      />
      <Script
        id="admedia"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://load.t.hondubet.com/bsd.js'+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer');
            `,
        }}
      />
      <Script
        id="xtremepush-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                  (function(p,u,s,h,e,r,l,i,b) {p['XtremePushObject']=s;p[s]=function(){
                    (p[s].q=p[s].q||[]).push(arguments)};i=u.createElement('script');i.async=1;
                    i.src=h;b=u.getElementsByTagName('script')[0];b.parentNode.insertBefore(i,b);
                  })(window,document,'xtremepush','https://us.webpu.sh/${process.env.NEXT_PUBLIC_XP_KEY}/sdk.js');
          `,
        }}
      />
      <Script
        id="apple-pay-sdk"
        src="https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js"
        strategy="lazyOnload"
      />
    </>
  );
};

export default ScriptsLoader;
